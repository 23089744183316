#footer .footer1 {
    background-color: #0B2D35;
}

#footer .footer1 .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
}

#footer .footer1 .container .left {
    text-align: left;
}

#footer .footer1 .container .left img {
    width: 50%;
}

#footer .footer1 .container .right {
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    gap: .5rem;
}

#footer .footer1 .container .right .title {
    font-size: 20px;
    text-transform: initial;
}

#footer .footer1 .container .right .ctas {
    display: flex;
    flex-direction: column;
}

#footer .footer1 .container .right .ctas a {
    display: flex;
    align-items: center;
    gap: .5rem;
}

#footer .footer1 .container .right .ctas a figure {
    width: 8%;
}

#footer .footer1 .container .right .ctas a p {
    font-size: 16px;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#footer .footer2 .container p {
    color: black;
    font-size: 16px;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container {
        grid-template-columns: 1fr;
    }

    #footer .footer1 .container .left {
        text-align: center;
    }

    #footer .footer1 .container .left img {
        width: 40%;
    }

    #footer .footer1 .container .right {
        justify-self: center;
        gap: 1rem;
    }

    #footer .footer1 .container .right .title {
        font-size: 18px;
    }

    #footer .footer1 .container .right .ctas {
        flex-direction: row;
        gap: 2rem;
    }

    #footer .footer1 .container .right .ctas a {
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    #footer .footer1 .container .right .ctas a figure {
        width: 25px;
    }

    #footer .footer1 .container .right .ctas a p {
        font-size: 14px;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }

    #footer .footer2 .container p {
        font-size: 14px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container {
        gap: 1rem;
    }

    #footer .footer1 .container .left img {
        width: 50%;
    }

    #footer .footer1 .container .right .title {
        font-size: 16px;
    }

    #footer .footer1 .container .right .ctas a p {
        font-size: 12px;
    }

    #footer .footer2 .container p {
        font-size: 12px;
    }
}