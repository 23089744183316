#formPage {
  background-color: #FCF7F2;
}

#formPage .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
}

#formPage .container .right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#formPage .container .right .top {
  display: flex;
  align-items: center;
  gap: .1rem;
}

#formPage .container .right .top figure {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#formPage .container .right .top .text {
  font-size: 16px;
  color: #2E3D41;
}

#formPage .container .right figure img {
  border-radius: 7px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #formPage .container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  #formPage .container .right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  #formPage .container .right .top {
    flex-direction: column;
    gap: 0;
  }

  #formPage .container .right .top figure {
    width: 7%;
  }

  #formPage .container .right .top .text {
    font-size: 15px;
    text-align: center;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #formPage .container .right .top .text {
    font-size: 14px;
  }

  #formPage .container .right .top figure {
    width: 10%;
  }
}