#success {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#success .container {
    height: 100%;
    display: flex;
    align-items: center;
}

#success .container .title {
    text-transform: initial;
    max-width: 700px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #success {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center 0;
        background-size: cover;
        height: 80vh;
    }

    #success .container {
        align-items: flex-end;
        padding-bottom: 4rem;
    }

    #success .container .title {
        max-width: 100%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #success {
        height: 70vh;
    }

    #success .container {
        padding-bottom: 3rem;
    }
}