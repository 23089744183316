#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
}

#header .header1 .container .title {
    max-width: 740px;
}

#header .header1 .container .text {
    max-width: 700px;
}

#header .header2 {
    background-color: #FCF7F2;
}

#header .header2 .container .title {
    text-align: center;
}

#header .header2 .container #ctaWpp {
    margin: 0 auto;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center 0;
        background-size: cover;
        height: 95vh;
    }

    #header .header1 .container {
        justify-content: flex-end;
        padding-bottom: 4rem;
    }

    #header .header1 .container .title {
        max-width: 100%;
    }

    #header .header1 .container .text {
        max-width: 100%;
        text-align: center;
    }

    #header .header1 .container #ctaWpp {
        margin: 0 auto;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container {
        padding-bottom: 2rem;
    }

    #header .header2 .container .mobile {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    #header .header2 .container .mobile figure img {
        width: 90%;
    }
}