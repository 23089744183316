#menu {
    background-color: #0B2D35;
    border-bottom: 1px solid var(--color-title);
}

#menu .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

#menu .container .left img {
    width: 20%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container .left img {
        width: 40%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container .left img {
        width: 50%;
    }
}