#about .about1 {
  background: url("../../assets/about1.png") no-repeat center center;
  background-size: cover;
}

#about .about1 .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

#about .about1 .container .text {
  max-width: 650px;
  font-weight: 300;
}

#about .about2 .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 2rem;
}

#about .about2 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container .left .mobile {
  display: none;
}

#about .about2 .container .left .text {
  color: black;
}

#about .about2 .container .left #ctaWpp {
  width: 65%;
}

#about .about2 .container .right img {
  width: 80%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 {
    background: url("../../assets/mobile/about1.png") no-repeat center 0;
    background-size: cover;
    height: 115vh;
  }

  #about .about1 .container {
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 3rem;
  }

  #about .about1 .container .text {
    max-width: 100%;
    text-align: center;
  }

  #about .about1 .container #ctaWpp {
    margin: 0 auto;
  }

  #about .about2 .container {
    grid-template-columns: 1fr;
  }

  #about .about2 .container .left .mobile {
    display: block;
  }

  #about .about2 .container .left .mobile img {
    width: 40%;
  }

  #about .about2 .container .left .text {
    text-align: center;
  }

  #about .about2 .container .left #ctaWpp {
    width: 60%;
    margin: 0 auto;
  }

  #about .about2 .container .right {
    display: none;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container {
    padding-bottom: 2rem;
  }

  #about .about2 .container .left #ctaWpp {
    width: 75%;
  }
}